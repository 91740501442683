<template>
    <div class="introduce">
        <div id="demo"></div>
        <div class="btn">
            <van-button round type="info" size="large" class="up" @click="next">{{btn_text}}</van-button>
        </div>
        <div class="goBack" @click="$router.back()">
            <img src="/static/goBack.png" />
        </div>
    </div>
</template>

<script>
    import Pdfh5 from "pdfh5";
    import "pdfh5/css/pdfh5.css"
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState
    } from 'vuex';
    import {
        Dialog
    } from 'vant'
    import {
        appHost
    } from '@/utils/host'
    import {parseUrl} from '@/utils/utils'
    export default {
        name: '',
        data() {
            return {
                project_doctor_id: null,
                baseFileUrl: appHost,
                pdfh5: null,
                btn_text: ''
            }
        },
        mounted() {
            this.project_doctor_id = this.$route.query.project_doctor_id
            this.init()
        },
        computed: {
            ...mapGetters(['project_id']),
            ...mapState(['basicInformation'])
        },
        methods: {
            ...mapMutations(['clearImgList','clearIdcardList']),
            init() {
                this.btn_text = '提交资质审核'
                this.pdfh5 = new Pdfh5("#demo", {
                    pdfurl: parseUrl(sessionStorage.getItem('informed_consent_sign'), this.baseFileUrl),
                });
                //监听完成事件
                this.pdfh5.on("complete", function (status, msg, time) {
                    console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
                })
            },
            next() {
                Dialog.alert({
                    message: '提交审核后不可修改，是否确认提交审核？',
                    title: '确定提交',
                    showCancelButton: true,
                    cancelButtonText: '我再想想',
                    confirmButtonColor: '#4B99FF',
                    confirmButtonText: '确定提交'
                }).then(() => {
                    sessionStorage.removeItem('informed_consent_sign')
                    this.clearImgList()
                    this.clearIdcardList()
                    this.$router.replace({
                        path: '/sub_project',
                    })
                }).catch(() => {})
            },
        }
    }
</script>

<style lang="scss">
html, body, #app {
  height: 100%; box-sizing: border-box;
}
</style>
<style lang="scss" scoped>
    .introduce {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 80px;

        .text {
            padding: 10px;
            box-sizing: border-box;
            padding-bottom: constant(safe-area-inset-bottom);
            padding-bottom: env(safe-area-inset-bottom);
            margin-bottom: 70px;
        }
    }
</style>